import React, { useEffect, forwardRef } from "react";
import classnames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

import "./page.scss";

interface PageProps extends ComponentPropsWithoutRef<"div"> {
  children: React.ReactElement | React.ReactElement[];
  darkenBg?: boolean;
}
const Page = ({ children, darkenBg, ...rest }: PageProps) => {
  useEffect(() => {
    const page = document.getElementById("app");
    page?.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={classnames("pageWrapper")} id="pageWrapper">
      <div className={classnames("page")} id="page" {...rest}>
        {children}
      </div>
    </div>
  );
};

export default Page;
