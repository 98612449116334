import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";

import Banner from "../banner/banner";
import Page from "../page/page";

import "./BookingPage.scss";
import classNames from "classnames";

const bookingHoursSlots = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
const bookingMinutesSlots = [0, 15, 30, 45];

const BookingPage = () => {
  const today = new Date();
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const [confirmedDate, setConfirmedDate] = useState(new Date());
  const [showBookingModule, setShowBookingModule] = useState(false);

  useEffect(() => {
    // document.addEventListener("DOMContentLoaded", function () {
    //   var widget = document.createElement("script");
    //   widget.src = "https://widget.weezevent.com/weez.js";
    //   document.body.appendChild(widget);
    // });
    //   fetch(
    //     `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/bookings`,
    //   )
    //     .then((res) => {
    //       res.json().then((bookings) => {
    //         console.log(bookings);
    //       });
    //     })
    //     .catch(() => {});
  }, []);
  const handleShowBookingModule = () => {
    setShowBookingModule(true);
    var widget = document.createElement("script");
    widget.src = "https://widget.weezevent.com/weez.js";
    document.body.appendChild(widget);
  };
  return (
    <>
      <Page>
        <Banner
          texts={[`Réservation de sessions`]}
          style={{ marginTop: "100px" }}
        />

        <div className="bookingExplanation">
          Notre premier terrain de jeu est à Ivry en
          <span className="highlightText">Île de France !</span>
          <br />
          <br />
          Ca se passe dans l'espace{" "}
          <span className="highlightText">Urban Soccer Ivry</span> et il y a{" "}
          <span className="highlightText">3 étapes</span> pour réserver une
          session Shotam !
        </div>
        <div className="bookingExplanation">
          <span className="explanationNumber">1</span>
          Choisis ta session et réserve en 3 clics.
          <br />
          {!showBookingModule && (
            <button
              className="showBookingButton"
              onClick={handleShowBookingModule}
            >
              Commencer
            </button>
          )}
          {showBookingModule && (
            <a
              title="Logiciel billetterie en ligne"
              href="https://widget.weezevent.com/ticket/E1257682/?code=12934&locale=fr-FR&width_auto=1&color_primary=fcb900"
              className="weezevent-widget-integration"
              data-src="https://widget.weezevent.com/ticket/E1257682/?code=12934&locale=fr-FR&width_auto=1&color_primary=fcb900"
              data-id="1257682"
              data-resize="1"
              data-width_auto="1"
              data-noscroll="0"
              data-use-container="yes"
              data-type="neo"
              target="_blank"
            >
              Ouvrir la billetterie
            </a>
          )}
        </div>
        <div className="bookingExplanation">
          <span className="explanationNumber">2</span>
          Il faut<span className="highlightText">minimum 6 joueurs</span> par
          session de jeu sans quoi elle sera reportée à une autre date !
          <br />
          <br />
          Tu peux inviter tes amis en leur partageant cette page !
          <div className="shareContainer">
            {/* @ts-ignore */}
            <FacebookShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <FacebookIcon />
            </FacebookShareButton>
            {/* @ts-ignore */}
            <TwitterShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <TwitterIcon />
            </TwitterShareButton>
            {/* @ts-ignore */}
            <LinkedinShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <LinkedinIcon />
            </LinkedinShareButton>
            {/* @ts-ignore */}
            <WhatsappShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <WhatsappIcon />
            </WhatsappShareButton>
          </div>
          <br />
        </div>
        <div className="bookingExplanation">
          <span className="explanationNumber">3</span>
          C'est tout bon, il ne te reste plus qu'à venir le jour J !
          <br />
          <br /> Entre temps, on reste joignables à l'adresse suivante :
          <span className="highlightText">contact@shotam.app</span>
          <br />
          <br />A bientôt sur le terrain de jeu !
          <div className="explanationHint">
            Attention à bien être à l'heure, le terrain est réservé sur un
            créneau fixe.
          </div>
        </div>
      </Page>
    </>
  );
};

export default BookingPage;
